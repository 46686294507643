import React from "react";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import "./normdata.css";
import usePrevious from "../../hooks/usePrevious";
import { getPFMonth } from "./GraphQueryNew";

const FinderMonth = (props) => {
  const pickerLang = {
    months: [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ],
    from: "From",
    to: "To",
  };

  const [singleValue, setSingleValue] = React.useState(() => {
    if (props.initialMonthYear) {
      const year = props.initialMonthYear.substring(0, 4);
      const month = Number(props.initialMonthYear.substring(4));
      return { year, month };
    }
    
    // Default to current year and month if initialMonthYear is not provided
    const [currentYearForPF, monthForPF] = getPFMonth();
    return { year: currentYearForPF, month: Number(monthForPF) };
  });

  const prevGsm = usePrevious(props.value);
  const pickRange = React.createRef();

  function subtract12Months(date) {
    date.setMonth(date.getMonth() - 12);
    return date;
  }

  const current = new Date();
  const prevDate = subtract12Months(current);

  const makeText = (m) => {
    if (m && m.year && m.month) 
      return pickerLang.months[m.month - 1] + ". " + m.year;
    return "?";
  };

  const handleClickMonthBox = (e) => {
    pickRange.current.show();
  };

  const handleAMonthChange = (...args) => {
    const value = { year: args[0], month: args[1] };
    const monthString = 
      value.year + (value.month >= 10 ? "" : "0") + value.month;

    if (props.tableName !== "" || props.isSaveDone) {
      if (prevGsm !== monthString) 
        props.setIsMonthChangedAfterPreview(true);
    } else {
      setSingleValue(value);
      props.setValue(monthString);
    }

    pickRange.current.dismiss();
  };

  React.useEffect(() => {
    if (props.value) {
      const year = props.value.substring(0, 4);
      const month = Number(props.value.substring(4));
      setSingleValue({ year, month });
    }
  }, [props.value]);

  return (
    singleValue && (
      <div className="editPicker">
        <img
          src="../../AppAssets/calander.png"
          alt="cal"
          style={{ margin: "0 .5rem 0 .5rem" }}
          onClick={handleClickMonthBox}
        />
        <ReactMonthPicker
          ref={pickRange}
          years={{
            min: { year: prevDate.getFullYear(), month: prevDate.getMonth() + 1 },
            max: { year: singleValue.year, month: singleValue.month },
          }}
          value={singleValue}
          lang={pickerLang}
          theme="light"
          onChange={handleAMonthChange}
          className="custom"
        >
          <MonthBox
            value={makeText(singleValue)}
            onClick={handleClickMonthBox}
          />
        </ReactMonthPicker>
      </div>
    )
  );
};

export const MonthBox = (props) => {
  const handleClick = (e) => {
    props.onClick && props.onClick(e);
  };
  return (
    <div className="box" onClick={handleClick}>
      <label>{props.value || "N/A"}</label>
    </div>
  );
};

export default FinderMonth;
