import React from 'react';
import { Box } from "@mui/system";
import MultiSelectDropDown from "../../GlobalComponent/Dropdown/MultiSelectDropDown1";
import { useState, useEffect } from "react";
import { getChartData, getChartDataQTY, loadSavingPotential, loadBarChart, getPFMonth, loadScenarioQuery, callAPI, changeTheScenarioDataFormat, querySavingPotentialEditedSave, setStatetoContext, loadFilters } from "./GraphQueryNew";
import Loader from "../../GlobalComponent/loader";
import { Button } from "@mui/material";
import { useAuth } from "../../GlobalComponent/Authentication/AuthProvider";

import Tooltip from "@mui/material/Tooltip";
import pfContext from "../../context/PfContext";
import { useContext } from 'react';
import tooltipsContent from '../../GlobalComponent/NavBar/tooltips.json';
import NormChart from "./NormChart1";
import PfFilter from './PfFilter';
import "./customizeNorm.css";
import axios from "axios";
import TextFieldEditor from './TextFieldEditor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoadPrevScenario from './LoadPrevScenario';
import FinderMonth from "./FinderMonth1";
import NormDataTable from './NormDataTable1';
import CustomizeNormContainer from "./CustomizeNormContainer";
import ModalPopup from '../../GlobalComponent/ModalPopup.js';
import usePrevious from "../../hooks/usePrevious";

function NewPotentailFinder() {
	const { user } = useAuth();
	const kpi = [{ "id": "Current", name: "Total Inventory" },
	{ "id": "12 MA Projection", name: "BIIR 12MA Projected Inventory(owned)" },
	{ "id": "YE Target", name: "YE BIIR Projected Inventory(owned)" },
	{ "id": "Historized", name: "12MA Norm+ @historized" },
	{ "id": "As-is", name: "12MA Norm+ @as-is" },
	{ "id": "Improved", name: "12MA Norm+ @improved" },
	{ "id": "Customized", name: "12MA Norm+ @customized" },
	{ "id": "YE Inventory Target", name: "YE Inventory Target" }
		, { "id": "YE Inventory FC", name: "YE Inventory FC (Norm @as-is)" }];
	const [selectedKPI, setSelectedKPI] = useState(["Current", "As-is", "Customized", "Improved", "YE Inventory Target", "YE Inventory FC"]);
	const [chartButton, setChartButton] = React.useState("VAL");
	const [chartData, setChartData] = React.useState([]);
	const [masterChartData, setMasterChartData] = React.useState([]);
	//const [normsData, setNormsData] = React.useState(null);
	const [loader, setLoader] = useState(false);
	const context = useContext(pfContext);
	const buttonRef = React.useRef(null);
	const [spData, setSpData] = useState([]);
	const [scenarioList, setScenarioList] = useState([]);
	const [MonthYear, setMonthYear] = useState("");
	const [scenario, setScenario] = useState("");
	const [showError, setShowError] = React.useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [resetModal, setResetModal] = React.useState(false);
	const [deleteModal, setDeleteModal] = React.useState(false);
	const [tableName, setTableName] = useState("");
	const role = (user != null && user.role != null) ? user.role : "";
	const cwid = (user !== null && user.user_cwid !== null) ? user.user_cwid : "";
	let userGsm = (user != null && user.gsm_network_code != null) ? user.gsm_network_code : "";
	const [isRefreshClicked, setIsRefreshClicked] = useState(false);
	const [isDeleteClicked, setIsDeleteClicked] = useState(false);
	const [isGsmChangedAfterPreview, setIsGsmChangedAfterPreview] = React.useState(false);
	const [isMonthChangedAfterPreview, setIsMonthChangedAfterPreview] = React.useState(false);
	const prevGsm = usePrevious(context.gsmNetwork);
	const [isPrevSceLoad, setIsPrevSceLoad] = useState(false);
	const [isCleared, setIsCleared] = useState(false);
	const [everyThing, setEveryThing] = useState(false);
	const [isSaveDone, setIsSaveDone] = React.useState(false);
	const [isInitLoad, setIsInitLoad] = useState(false);
	const [filtersUpdatedOnIInitLoad, setFiltersUpdatedOnIInitLoad] = useState(false);

	const getSnapShotId = () => {
		const [pfYear, pfMonth] = getPFMonth();
		const snpshotId = pfYear + "" + pfMonth
		return snpshotId;
	}


	/*Load PreviouslyCreatedSCenario*/
	useEffect(() => {
		setLoader(true);
		const cancelToken = axios.CancelToken.source();
		getScenario(cancelToken.token);

		return () => {
			cancelToken.cancel();
		}

	}, [])



	const getScenario = (token) => {
		const queryForScenarioList = loadScenarioQuery("", cwid);
		setLoader(true);
		callAPI(queryForScenarioList, token).then((response) => {
			if (response.data !== undefined && response.data.CustomizedView_LoadPreviousScenario !== null) {
				setScenarioList(
					changeTheScenarioDataFormat(
						response.data["CustomizedView_LoadPreviousScenario"]["list_of_scenarios"]
					));
			}
			//setLoader(false);
		}).catch((error) => {
			console.log("error", error);
		})
	}

	const parseFilterValues = (values) => {
		return values ? values.split(",") : [];
	};
	
	const getFilters = (token) => {
		return new Promise((resolve, reject) => {
			const LoadFilters = loadFilters("PF", cwid);
			callAPI(LoadFilters, token).then((response) => {
				console.log(context, 'context')
				if (response.data !== undefined &&
					response.data !== undefined &&
					response.data.FiltersLoad) {
					const filters = response.data.FiltersLoad;
					// setNewDataFilters(filters);
					const monthYearValue = filters.month_start_or_cur;

					// Assuming you want to pass this down as a prop
					setMonthYear(monthYearValue);
					const gsmFilter = setStatetoContext(parseFilterValues(filters["GSM_Values"]));
					const brandFilter = setStatetoContext(parseFilterValues(filters["Brand_Values"]));
					const locFilter = setStatetoContext(parseFilterValues(filters["Location_Values"]));
					const comFilter = setStatetoContext(parseFilterValues(filters["CoM_Values"]));
					const proFilter = setStatetoContext(parseFilterValues(filters["Product_Values"]));

					// Log parsed filters
					// console.log("GSM Filter:", gsmFilter);
					// console.log("Brand Filter:", brandFilter);
					// console.log("Location Filter:", locFilter);
					// console.log("CoM Filter:", comFilter);
					// console.log("Product Filter:", proFilter);

					context.setIsGsmSelectAll(filters["GSM_Sel_All"] === "true");
					context.setIsBrandSelectAll(filters["Brand_Sel_All"] === "true");
					context.setIsLocationSelectAll(filters["Location_Sel_All"] === "true");
					context.setIsCoMSelectAll(filters["CoM_Sel_All"] === "true");
					context.setIsProductSelectAll(filters["Product_Sel_All"] === "true");

					if (gsmFilter !== null) { context.setGsmNetwork(gsmFilter); }
					if (brandFilter !== null) {context.setPipeline(brandFilter);}
					if (locFilter !== null) context.setLocation(locFilter);
					if (comFilter !== null) context.setCom(comFilter);
					if (proFilter !== null) context.setProduct(proFilter);
					resolve();  // Resolve the promise after updates are done
				}

			}).catch((error) => {
				console.error("error", error);
				reject(error);  // Reject in case of error
			});
		});
	};
	// console.log(newDataFilters)

	/*Load Bar Chart and SavingPotential on pageLoad */
	useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchData = async () => {
        try {
            setLoader(true);
            const snpshotId = getSnapShotId();
            setMonthYear(snpshotId);
			
            await getFilters(cancelToken.token);  // Wait for getFilters to complete
			setIsInitLoad(true)
            setFiltersUpdatedOnIInitLoad(true); // Set to true after context and state updates
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("Request canceled", error.message);
            } else {
                console.error("Error in fetching data", error);
            }
        } 
    };

    fetchData();

    return () => {
        cancelToken.cancel();
    };
}, []);

useEffect(() => {
    if (filtersUpdatedOnIInitLoad) {
        const cancelToken = axios.CancelToken.source();
        const snpshotId = getSnapShotId();

        loadPfChartAndSavingPotentialData(cancelToken.token, snpshotId);
    }
}, [filtersUpdatedOnIInitLoad]); // Call loadPfChartAndSavingPotentialData after filtersUpdatedOnIInitLoad is true
	
	/*API call for Chart Bar and SavingPotential Data */

	useEffect(() => {
		const cancelToken = axios.CancelToken.source();

		if (isRefreshClicked) {
			setLoader(true);
			//setScenario("");
			//document.getElementById("secnarioName").value="";
			loadPfChartAndSavingPotentialData(cancelToken.token, null);


		}
		return () => {
			cancelToken.cancel();
		}
	}, [isRefreshClicked])
	const loadPfChartAndSavingPotentialData = (token, snapShotId) => {
		//setLoader(true);
		//console.log("context",context);
		try {
			const snapId = (snapShotId !== null) ? snapShotId : MonthYear;
			const { gsmCode, locCode, comCode, proCode, pipCode } = context.getFilterValues();
			const inputParam = `Brand_Sel_All: ${context.isBrandSelectAll}, 
		Brand_Values: "${pipCode}", CoM_Sel_All: ${context.isCoMSelectAll}, CoM_Values: "${comCode}", 
		CWID: "${cwid}", GSM_Sel_All: false, Location_Sel_All: ${context.isLocationSelectAll},
		 Location_Values: "${locCode}", Product_Sel_All: ${context.isProductSelectAll}, Product_Values: "${proCode}",
		  snapshot: "${snapId}", customized_table_name: "${tableName}",
		   GSM_Values: "${gsmCode}"`;

			const inputParam_sp = `Brand_Sel_All: ${context.isBrandSelectAll},  
		   Brand_Values: "${pipCode}", CoM_Sel_All: ${context.isCoMSelectAll}, CoM_Values: "${comCode}", 
		   CWID: "${cwid}", GSM_Sel_All: false, Location_Sel_All: ${context.isLocationSelectAll},
			Location_Values: "${locCode}", Product_Sel_All: ${context.isProductSelectAll}, Product_Values: "${proCode}",
			 snapshot: "${snapId}", customized_table_name: "${tableName}",
			  GSM_Values: "${gsmCode}", sort_type: "Top 10"`;


			Promise.all([
				loadBarChart(inputParam, token),
				loadSavingPotential(inputParam_sp, token)

			]).then(([barResponse, spResponse]) => {
				if (barResponse.data.PotentialFinderBars !== null)
					setMasterChartData(barResponse.data.PotentialFinderBars["CHART"]);

				if (spResponse.data.PotentialFinderSavingPotential !== null) {
					setSpData(spResponse.data.PotentialFinderSavingPotential);
				}

				setIsPrevSceLoad(false);
				setIsInitLoad(false);
				setFiltersUpdatedOnIInitLoad(false);
				setIsRefreshClicked(false);
				setLoader(false);

			}).catch((err) => {
				setLoader(false);
				setIsRefreshClicked(false);
				console.log(err.message);
			});

		}
		catch (error) {
			setLoader(false);
			setIsRefreshClicked(false);
			console.log(error.message);
		}
	}

	/*Reset all the Preview Scenario from DB */
	React.useEffect(() => {
		//setLoader(true);
		const cancelToken = axios.CancelToken.source();
		try {
			const resetQuery = `mutation MyMutation { RESET(CWID: "${cwid}"){T_F RESET}}`
			callAPI(resetQuery, cancelToken.token);

		} catch (error) {
			// debugger;
			console.log(error);
			//showErrorModal(error.response.data.errors[0].message);
			setLoader(false);
		}
		return () => {
			cancelToken.cancel();
		}
	}, [""]);

	React.useEffect(() => {
		// console.log("selectedKPI");
		if (masterChartData != null) {
			if (selectedKPI.length > 0) {

				if (chartButton == "VAL") setChartData(getChartData(masterChartData, selectedKPI));
				else if (chartButton == "QTY") setChartData(getChartDataQTY(masterChartData, selectedKPI));
				//console.log("MarkerValue"+masterChartData['CURRENT'].CURRENT);
			} else {
				setChartData([]);
			}
		}
		// setLoader(false);
	}, [masterChartData, selectedKPI]);



	/*toggle data value from EURO to QTY */
	function showEURToQTY(e) {
		if (e.target.value == "VAL") {
			if (masterChartData != null) {
				e.target.value = "QTY";
				setChartButton("QTY");
				// e.target.innerHTML="QTY";

				setChartData(getChartDataQTY(masterChartData, selectedKPI));
			}
		} else if (e.target.value == "QTY") {
			if (masterChartData != null) {
				e.target.value = "VAL";
				setChartButton("VAL");

				// e.target.innerHTML="EUR";
				setChartData(getChartData(masterChartData, selectedKPI));
			}
		}
		//console.log("button");
	}



	useEffect(() => {
		const cancelToken = axios.CancelToken.source();
		if (isDeleteClicked) {
			setLoader(true);
			//console.log("isDeleteClicked",isDeleteClicked);
			const secnarioName1 = document.getElementById("secnarioName").value;
			const gsmCode = (context.gsmNetwork)?.map((item) => (item.code)).toString();
			// if (
			// 		role &&
			// 		((userGsm === gsmCode && role.toLowerCase().includes("e2e planner")) ||
			// 			role.toLowerCase() == "admin" ||
			// 			role.toLowerCase() == "gsm")
			// 	){
			if (secnarioName1 !== "") {
				const deleteQuery = `mutation MyMutation {Delete_Scenario(scenario_name:"${secnarioName1}",CWID:"${cwid}")}`
				callAPI(deleteQuery, cancelToken.token).then((response) => {

					if (response.data["Delete_Scenario"] == "Customized Table Deleted Successfully") {
						const newList = scenarioList.filter(o => o.Name != secnarioName1)
						setIsSaveDone(false)
						setScenarioList(newList);
						resetEveryThing();
						setDeleteModal(false);
					}
					else {
						setLoader(false);
					}


				}
				)

			}
			//}


		}
		return () => {
			cancelToken.cancel();
		}
	}, [isDeleteClicked])

	function resetEveryThing() {
		setEveryThing(true);
	}

	useEffect(() => {
		if (everyThing) {
			resetEveryThing1();
		}

	}, [everyThing])

	function resetEveryThing1() {

		console.log("rsetData");
		setIsSaveDone(false)
		setTableName("");
		setScenario("");
		context.setIsGsmSelectAll(false);
		context.setIsBrandSelectAll(true);
		context.setIsLocationSelectAll(true);
		context.setIsProductSelectAll(true);
		context.setIsCoMSelectAll(true);
		context.setGsmNetwork([]);
		context.setLocation([]);
		context.setProduct([]);
		context.setCom([]);
		context.setPipeline([]);
		setIsCleared(true);
		setMonthYear(getSnapShotId());
		document.getElementById("secnarioName").value = "";
		setIsPrevSceLoad(false);
		setIsGsmChangedAfterPreview(false);
		setIsMonthChangedAfterPreview(false);
		//context.setIsResetContext(true);




		setDeleteModal(false);
		setIsDeleteClicked(false);
		setIsRefreshClicked(true);
		setEveryThing(false);


	}
	// set the delete state for deleting the Scenario
	const setDeleteState = () => {
		setIsDeleteClicked(true);
	}

	const handleSavingPotentialSave = (filterNormsData) => {
		const cancelToken = axios.CancelToken.source();
		setLoader(true);
		const comments = filterNormsData.map(item => chartButton === "VAL" ? item.Comment : item.Comment_QY).join(',');
		const entryType = chartButton === "VAL" ? filterNormsData[0]['Type'] : filterNormsData[0]['Type'] + "_QY";
		const entryInfo = filterNormsData.map(item => item[entryType]).join(';');
		const tickers = filterNormsData.map(item => chartButton === "VAL" ? item.Tick : item.Tick_QY).join(',');
		const typeNorm = filterNormsData[0]['Type'];
		const typeSelect = filterNormsData[0]['basedon'].replace(/-NORM_Plus/g, '').toLowerCase().replace(/-/g, "_");
		
		const saveQuery = querySavingPotentialEditedSave(comments,
			entryInfo,
			MonthYear,
			tickers,
			typeNorm,
			typeSelect)
		callAPI(saveQuery,cancelToken.token)
			.then((response)=>{

				const snapId=MonthYear;
	  			const {gsmCode,locCode,comCode,proCode,pipCode}=context.getFilterValues();
		
				const inputParam_sp=`Brand_Sel_All: ${context.isBrandSelectAll}, 
					Brand_Values: "${pipCode}", CoM_Sel_All: ${context.isCoMSelectAll}, CoM_Values: "${comCode}", 
					CWID: "${cwid}", GSM_Sel_All: false, Location_Sel_All: ${context.isLocationSelectAll},
					Location_Values: "${locCode}", Product_Sel_All: ${context.isProductSelectAll}, Product_Values: "${proCode}",
					snapshot: "${snapId}", customized_table_name: "${tableName}",
					GSM_Values: "${gsmCode}", sort_type: "Top 10"`;
				loadSavingPotential(inputParam_sp, cancelToken.token)
					.then((spResponse) => {
						if (spResponse.data.PotentialFinderSavingPotential !== null) {
							setSpData(spResponse.data.PotentialFinderSavingPotential);
						}
						setLoader(false);
						setErrorMsg("Saving Potential Selection is successful");
						setShowError(true);
					})
			})
	}


	const handleFilterSavingPF = () => {
		const cancelToken = axios.CancelToken.source();
		const { gsmCode, locCode, comCode, proCode, pipCode } = context.getFilterValues();
		const querySavingFilters = `query MyQuery {FiltersSave(
		Brand_Sel_All: "${context.isBrandSelectAll}", 
		Brand_Values: "${pipCode}", CoM_Sel_All: "${context.isCoMSelectAll}", CoM_Values: "${comCode}", 
		CWID: "${cwid}", GSM_Sel_All: "false", Location_Sel_All: "${context.isLocationSelectAll}",
		Location_Values: "${locCode}", Product_Sel_All: "${context.isProductSelectAll}", Product_Values: "${proCode}",
		month_end: "", month_start_or_cur: "${MonthYear}", 
		  portlet: "PF", GSM_Values: "${gsmCode}"
		  )
		  }`;
		callAPI(querySavingFilters, cancelToken.token)
	}

	return (<>
		<div className="filterBarParent">
			<div className="filterBar">
				<div className="boxFlex">
					{/*Filter for Potential Finder */}
					<PfFilter filterType="PF" isInitLoad={isInitLoad} isPrevSceLoad={isPrevSceLoad} isSaveDone={isSaveDone} tableName={tableName} setIsGsmChangedAfterPreview={setIsGsmChangedAfterPreview} isCleared={isCleared} setIsCleared={setIsCleared} />
					<Tooltip
						title={
							<span style={{ fontSize: '16px' }}>
								{tooltipsContent.MultiSelectDropDown}
							</span>
						}
						open={context.showTooltips && context.buttonHoverStates === "MultiSelectDropDown"}>
						<Box className="boxFlex" style={{ marginLeft: "1rem" }}
							onMouseEnter={() => context.setButtonHoverStates(("MultiSelectDropDown"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}>
							<MultiSelectDropDown
								options={kpi}
								selectedValues={selectedKPI}
								setSelectedValues={setSelectedKPI}
								label="KPIs and NORMs"
							/>
						</Box>
					</Tooltip>

					<Tooltip
						title={
							<span style={{ fontSize: '16px' }}>
								{tooltipsContent.chartButton}
							</span>
						}
						open={context.showTooltips && context.buttonHoverStates === "chartButton"}>
						<Box className="boxFlex"
							onMouseEnter={() => context.setButtonHoverStates(("chartButton"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}>
							{" "}
							<Button
								id="chartButton"
								ref={buttonRef}
								className="load"
								onClick={(e) => showEURToQTY(e)}
								style={{ width: "14rem" }}
								value={chartButton}
							>
								{chartButton === "VAL" ? "VAL (€)" : "QTY (BUOM)"}
							</Button>
						</Box>
					</Tooltip>

					<Tooltip
						title={
							<span style={{ fontSize: '16px' }}>
								{tooltipsContent.chartButton}
							</span>
						}
						open={context.showTooltips && context.buttonHoverStates === "LoadData"}>
						<Box className="boxFlex"
							onMouseEnter={() => context.setButtonHoverStates(("LoadData"))}
							onMouseLeave={() => context.setButtonHoverStates((""))}>
							{" "}
							<Button
								id="LoadData"
								className="load"
								style={{ width: "14rem" }}
								onClick={(e) => {
									handleFilterSavingPF();
									setIsRefreshClicked(true)

								}
								}
							>
								Refresh Data
							</Button>
						</Box>
					</Tooltip>
				</div>
			</div>
		</div>
		<div className="scenarioContainer">
			<Box className="boxFlex" style={{ width: "55%" }}>
				<TextFieldEditor scenario={scenario} setScenario={setScenario} />
				{role &&
					(role.toLowerCase().includes("e2e planner") ||
						role.toLowerCase() == "admin" ||
						role.toLowerCase() == "gsm") ?
					<Tooltip
						title={
							<span style={{ fontSize: '16px' }}>
								{tooltipsContent.Delete}
							</span>
						}
						open={context.showTooltips && context.buttonHoverStates === "Delete"}>
						<DeleteForeverIcon className='cursor' onClick={() => { setDeleteModal(true) }} onMouseEnter={() => context.setButtonHoverStates(("Delete"))}
							onMouseLeave={() => context.setButtonHoverStates((""))} /></Tooltip> : null}

			</Box>
			<Box className="boxFlex" style={{ width: "45%" }}>
				<Box className="boxFlex" style={{ justifyContent: "flex-end" }}>
					<FinderMonth
						value={MonthYear}
						setValue={setMonthYear}
						tableName={tableName}
						isSaveDone={isSaveDone}
						initialMonthYear={MonthYear}

					// setIsMonthChangedAfterPreview={setIsMonthChangedAfterPreview}
					/>
				</Box>

				<Tooltip
					title={
						<span style={{ fontSize: '16px' }}>
							{tooltipsContent.LoadScenario}
						</span>
					}
					open={context.showTooltips && context.buttonHoverStates === "LoadScenario"}>
					<Box className="boxFlex" style={{ justifyContent: "flex-end" }}
						onMouseEnter={() => context.setButtonHoverStates(("LoadScenario"))}
						onMouseLeave={() => context.setButtonHoverStates((""))}>
						{<LoadPrevScenario list={scenarioList} scenario={scenario} isSaveDone={isSaveDone} setScenario={setScenario} setIsLoadSubmit={setIsPrevSceLoad} />}
					</Box></Tooltip>
			</Box>
		</div>

		<div className="scenarioDashboardContainer">
			<Box className="chartContainer" sx={{ height: "670px", width: "68%", position: "relative" }}>
				{loader ? <Loader /> : <NormChart chartData={chartData} />}
			</Box>
			<Box className="dataContainer">
				<NormDataTable
					chartButtonVal={chartButton}
					spData={spData}
					handleSavingPotentialSave={handleSavingPotentialSave}
				/>
			</Box>
		</div>

		<div className="customizeNormContainer">
			<CustomizeNormContainer

				//masterChartData={masterChartData}
				//setMasterChartData={setMasterChartData}
				//normsData={normsData}
				//setNormsData={setNormsData}
				scenarioName={scenario}
				monthYear={MonthYear}
				setMonthYear={setMonthYear}
				tableName={tableName}
				setTableName={setTableName}
				isLoadSubmit={isPrevSceLoad}
				setIsLoadSubmit={setIsPrevSceLoad}
				resetFilters={resetEveryThing}
				IsDeleted={setDeleteModal}
				setIsLoadData={setIsRefreshClicked}
				isLoadData={isRefreshClicked}
				setLoader={setLoader}
				setIsCleared={setIsCleared}
				getScenario={getScenario}
				isSaveDone={isSaveDone}
				setIsSaveDone={setIsSaveDone}

			//setIsPreviewSaved={setIsPreviewSaved}

			/>
		</div>

		{/*Show Delete Dialog*/}
		<ModalPopup show={deleteModal} message="Are you sure you want to delete data?" eventName={setDeleteState} btntext1="Cancel" btntext2="Delete" setShow={setDeleteModal} />
		{/*Show reset Dialog */}
		<ModalPopup show={isGsmChangedAfterPreview} message="Are you sure you want to Reset data?" eventName={resetEveryThing} btntext1="Cancel" btntext2="Reset" setShow={setIsGsmChangedAfterPreview} />
		<ModalPopup show={isMonthChangedAfterPreview} message="Are you sure you want to Reset data?" eventName={resetEveryThing} btntext1="Cancel" btntext2="Reset" setShow={setIsMonthChangedAfterPreview} />

		{/*Show any Dialog Msg */}
		<ModalPopup show={showError} message={errorMsg} btntext1="Ok" setShow={setShowError} />



	</>
	)
}

export default NewPotentailFinder
