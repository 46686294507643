import * as React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";
import Nodata from "../../GlobalComponent/NoData/Nodata";

function getMaxValue(data) {

	let chartData = [];
	let total=0;
	if (data != null) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].Id === "CURRENT") {
				chartData.push({...data[i],total:(data[i].CURRENT !== null && data[i].CURRENT !== undefined ? data[i].CURRENT : 0)});
			} else if (data[i].Id === "MA12Proj") {
				chartData.push({...data[i],total:(data[i].MA12Proj !== undefined && data[i].MA12Proj !== null ? data[i].MA12Proj : 0)});
			} else if (data[i].Id === "Ye Target") {
				chartData.push({...data[i],total:(data[i].YeTarget !== undefined && data[i].YeTarget !== null ? data[i].YeTarget : 0)});
			}
			else if (data[i].Id === "YE_Target") {
				chartData.push({...data[i],total:(data[i].YE_Target !== undefined && data[i].YE_Target !== null ? data[i].YE_Target : 0)});
			} else if (
				data[i].Id === "AS-IS" ||
				data[i].Id === "Improved" ||
				data[i].Id === "Customized" ||
				data[i].Id === "Historized" 
			) {
				total = (data[i].Cycle !== null && data[i].Cycle !== undefined) ? data[i].Cycle : 0;
				total += (data[i].Effects !== null && data[i].Effects !== undefined) ? data[i].Effects : 0;
				total += (data[i].Safety !== null && data[i].Safety !== undefined) ? data[i].Safety : 0;
				total += (data[i].InTransit !== null && data[i].InTransit !== undefined) ? data[i].InTransit : 0;
				total += (data[i].GoodsReceipt !== null && data[i].GoodsReceipt !== undefined) ? data[i].GoodsReceipt : 0;
				total += (data[i].GoodsIssued !== null && data[i].GoodsIssued !== undefined) ? data[i].GoodsIssued : 0;

				//Total:total;
				chartData.push({...data[i],total:total});
				//maxvalueArray.push(total);
			}
			else if(data[i].Id == "YE_Forecast")
			{
				total = (data[i].YECycle !== null && data[i].YECycle !== undefined) ? data[i].YECycle : 0;
				total += (data[i].YEEffects !== null && data[i].YEEffects !== undefined) ? data[i].YEEffects : 0;
				total += (data[i].YESafety !== null && data[i].YESafety !== undefined) ? data[i].YESafety : 0;
				total += (data[i].YEInTransit !== null && data[i].YEInTransit !== undefined) ? data[i].YEInTransit : 0;
				total += (data[i].YEGoodsReceipt !== null && data[i].YEGoodsReceipt !== undefined) ? data[i].YEGoodsReceipt : 0;
				total += (data[i].YEGoodsIssued !== null && data[i].YEGoodsIssued !== undefined) ? data[i].YEGoodsIssued : 0;

				//Total:total;
				chartData.push({...data[i],total:total});
			}
		}
		return chartData;
	}
}

const MyResponsiveBar = ({ data }) => {
	
	//console.log("data",data);

	const mk =
		data !== null && data[0] !== null && data[0]["CURRENT"] !== undefined && data[0]["CURRENT"] !== null
			? data[0]["CURRENT"]
			: 0;

	//console.log(mk);
	const BarComponent = ({ bar, borderColor }) => {
	

		const heighth=getCustomHeight(bar);
	
		const { showTooltipFromEvent, hideTooltip } = useTooltip();
		const shortText = bar.data.id;
		const value = bar.data.fill;
		const tooltipStyle = () => ({
			height: "auto",
			textAlign: "center",
			background: "#000000",
			color: "#ffffff",
			padding: "2rem",
		});
		
		return (
			<g
				transform={`translate(${bar.x - 15},${bar.y})`}
				onMouseEnter={(event) => {
					showTooltipFromEvent(
						<div style={tooltipStyle()}>
							{" "}
							<span>{(bar.data.id==="MA12Proj")?"BIIR 12MA Projection(owned)":(bar.data.id==="YeTarget")?"BIIR YE Projection(owned)":(bar.data.id=="InTransit" || bar.data.id=="YEInTransit")?"Transportation":(bar.data.id=="GoodsReceipt" || bar.data.id=="YEGoodsReceipt")?"Quality Stock Inbound":(bar.data.id=="GoodsIssued" || bar.data.id=="YEGoodsIssued")?"Quality Stock Outbound":(bar.data.id=="CURRENT")?"Total Inventory":bar.data.id}::</span> <br />
							<strong> {bar.data.value !== null || bar.data.value !== 0
									? parseFloat(bar.data.value).toLocaleString('en-US')
									: bar.data.value}</strong>
						</div>,
						event
					);
				}}
				onMouseLeave={() => hideTooltip()}
				onMouseMove={(event) =>
					showTooltipFromEvent(
						<div style={tooltipStyle()}>
							{" "}
							<span>{(bar.data.id==="MA12Proj")?"BIIR 12MA Projected Inventory(owned)":(bar.data.id==="YeTarget")?
							"YE BIIR Projected Inventory(owned)":(bar.data.id=="InTransit" 
								|| bar.data.id=="YEInTransit")? "Transportation":(bar.data.id=="GoodsReceipt" || 
								bar.data.id=="YEGoodsReceipt")? "Quality Stock Inbound":(bar.data.id=="GoodsIssued" || 
								bar.data.id=="YEGoodsIssued")?"Quality Stock Outbound":(bar.data.id=="CURRENT") ? 
								"Total Inventory":(bar.data.id=="YE_Target")?"YE Inventory Target": (bar.data.id=="YEEffects" || 
								bar.data.id=="YESafety" || bar.data.id=="YECycle")? "Inventory Forecast":bar.data.id}</span> <br />
							<strong> {bar.data.value !== null || bar.data.value !== 0
									? parseFloat(bar.data.value).toLocaleString('en-US')
									: bar.data.value}</strong>
						</div>,
						event
					)
				}
			>
				<rect
					width="8%"
					className="rect"
					id={bar.data.indexValue}
					height={heighth}
					fill={bar.data.fill}
					style={{
						clipPath: getInsetValue(bar),
						stroke:
							bar.data.id === "CURRENT"
								? "rgba(102, 181, 18, 0.48)"
								: value === "url(#MA12Proj)"
								? "rgba(69, 119, 3, 0.33)"
								: value === "url(#YETarget)"
								? "rgba(160, 180, 134, 0.35)"
								: value === "url(#YE_Target)"
								? "rgba(165, 185, 134, 0.35)"
								: "",
						strokeWidth: "2px",
					}}
				/>
				{(heighth) > 35 ? (
					bar.data.id.toLowerCase() === "current" ? (
						<text
							x="1%"
							y={heighth / 2}
							textAnchor="end"
							dominantBaseline="central"
							fill="white"
							style={{
								fontFamily: "Lato-Bold",
								fontSize: "1rem",
							}}>
								<tspan x="4.5%" y={heighth / 2 - 25}>
									{"Total"}
									
								</tspan>
							<tspan x="5.5%" y={heighth / 2 - 15}>
								{" Inventory"}
								
							</tspan>
							<tspan x="7%" y={heighth / 2}>
								{bar.data.value !== null || bar.data.value !== 0
									? parseFloat(bar.data.value).toLocaleString("en-US")
									: bar.data.value}
							</tspan>
						</text>
					):
						
						bar.data.id.toLowerCase() === "yetarget" 
						? (
							<text
								x="1%"
								y={heighth / 2}
								textAnchor="end"
								dominantBaseline="central"
								fill="white"
								style={{
									fontFamily: "Lato-Bold",
									fontSize: "1rem",
								}}
							>
								<tspan x="5.3%" y={heighth / 2 - 25}>
									{"BIIR YE"}
									
								</tspan>
								<tspan x="5.8%" y={heighth / 2 - 13}>
									{"Projection"}
									
								</tspan>
								<tspan x="7%" y={heighth / 2}>
									{bar.data.value != null || bar.data.value != 0
										? parseFloat(bar.data.value).toLocaleString("en-US")
										: bar.data.value}
								</tspan>
							</text>
						):bar.data.id.toLowerCase() === "ye_target" 
						? (
							<text
								x="1%"
								y={heighth / 2}
								textAnchor="end"
								dominantBaseline="central"
								fill="white"
								style={{
									fontFamily: "Lato-Bold",
									fontSize: "1rem",
								}}
							>

								<tspan x="7%" y={heighth / 2 - 30 }>
										{"YE Inventory"}			
								</tspan>
								<tspan x="5%" y={heighth / 2 - 18}>
									{"Target"}
									
								</tspan>
								<tspan x="7.3%" y={heighth / 2}>
									{bar.data.value != null || bar.data.value != 0
										? parseFloat(bar.data.value).toLocaleString("en-US")
										: bar.data.value}
								</tspan>
							</text>
						):bar.data.id.toLowerCase() === "ma12proj" ? (
								<text
									x="1%"
									y={heighth / 2}
									textAnchor="end"
									dominantBaseline="central"
									fill="white"
									style={{
										fontFamily: "Lato-Bold",
										fontSize: "1rem",
									}}
								>
									<tspan x="6%" y={heighth / 2 - 25}>
										{`BIIR 12MA`}
										
									</tspan>
									<tspan x="6%" y={heighth / 2 - 13}>
										{`Projection`}
										
									</tspan>
									<tspan x="7%" y={heighth / 2}>
										{bar.data.value !== null || bar.data.value !== 0
											? parseFloat(bar.data.value).toLocaleString("en-US")
											: bar.data.value}
									</tspan>
								</text>
							) : bar.data.id.toLowerCase() === "effects"  ||
					  bar.data.id.toLowerCase() === "safety"   ||
					  bar.data.id.toLowerCase() === "cycle" ? (
						<text
							x="1%"
							y={(heighth+10) / 2}
							textAnchor="end"
							dominantBaseline="central"
							fill="white"
							style={{
								fontFamily: "Lato-Bold",
								fontSize: "1rem",
							}}
						>
							<tspan x="5.5%" y={(heighth) / 2 - 2}>
								{bar.data.id}
							</tspan>
							<tspan x="6.8%" y={(heighth) / 2 + 9}>
								{bar.data.value != null || bar.data.value != 0
									? parseFloat(bar.data.value).toLocaleString('en-US')
									: bar.data.value}
							</tspan>
						</text>
					)
					:  bar.data.id.toLowerCase() === "yeeffects" ||
					  bar.data.id.toLowerCase() === "yesafety" || bar.data.id.toLowerCase() === "yecycle" 
					 ? (
						<text
							x="1%"
							y={heighth / 2}
							textAnchor="end"
							dominantBaseline="central"
							fill="white"
							style={{
								fontFamily: "Lato-Bold",
								fontSize: "1rem",
							}}>
								<tspan x="5.7%" y={heighth / 2 - 25}>
									{"Inventory"}
									
								</tspan>
							<tspan x="5.5%" y={heighth / 2 - 15}>
								{" Forecast"}
								
							</tspan>
							<tspan x="6.8%" y={(heighth) / 2 + 9}>
								{bar.data.value != null || bar.data.value != 0
									? parseFloat(bar.data.value).toLocaleString('en-US')
									: bar.data.value}
							</tspan>
						</text>
					)  : bar.data.id.toLowerCase() === "goodsreceipt" || bar.data.id.toLowerCase() === "yegoodsreceipt" || bar.data.id.toLowerCase() === "goodsissued" || bar.data.id.toLowerCase() === "yegoodsissued" ? (
						<text
							x="1%"
							y={(heighth) / 2}
							textAnchor="end"
							dominantBaseline="central"
							fill="white"
							style={{
								fontFamily: "Lato-Bold",
								fontSize: "1rem",
							}}
						>
							<tspan x="5.5%" y={(heighth+10) / 2 - 3}>
							{(bar.data.id=="GoodsReceipt" || bar.data.id=="YEGoodsReceipt")?"Quality Stock Inbound".substring(0, 7):(bar.data.id=="GoodsIssued")?"Quality Stock Outbound".substring(0, 7):bar.data.id}
								
							</tspan>
							<tspan x="7%" y={(heighth+10) / 2 + 6}>
							{(bar.data.id=="GoodsReceipt" || bar.data.id=="YEGoodsReceipt")?"Quality Stock Inbound".substring(8):(bar.data.id=="GoodsIssued")?"Quality Stock Outbound".substring(8):bar.data.id}
						
							</tspan>
							{heighth >= 57 ? (
								<tspan x="6.5%" y={(heighth+10) / 2 + 17.5}>
									{bar.data.value !== null || bar.data.value !== 0
										? parseFloat(bar.data.value).toLocaleString('en-US')
										: bar.data.value}
								</tspan>
							) : (
								""
							)}{" "}
						</text>
					) : (
						<text
							x="1%"
							y={(heighth) / 2}
							textAnchor="end"
							dominantBaseline="central"
							fill="white"
							style={{
								fontFamily: "Lato-Bold",
								fontSize: "1rem",
							}}
						>
							<tspan x="6.5%" y={(heighth) / 2 - 15}>
							{(bar.data.id=="InTransit" || bar.data.id=="YEInTransit")?"Transportation":bar.data.id}
						
							</tspan>
							<tspan x="7.5%" y={(heighth) / 2}>
								{bar.data.value != null || bar.data.value != 0
									? parseFloat(bar.data.value).toLocaleString('en-US')
									: bar.data.value}
							</tspan>
						</text>
					)
				) : (
					<text
						x="5.9%"
						y={(heighth)}
						textAnchor="end"
						dominantBaseline="central"
						fill="white"
						style={{
							fontFamily: "Lato-Bold",
							fontSize: "1rem",
						}}
					></text>
				)}
			</g>
		);
	};

	// console.log("data"+data);
	return (
		<ResponsiveBar
			data={data}
			keys={[
				"CURRENT",
				"MA12Proj",
				"YeTarget",
				"YE_Target",
				"Safety",
				"GoodsIssued",
				"GoodsReceipt",
				"InTransit",
				"Effects",
				"Cycle",
				"YESafety",
				"YEGoodsIssued",
				"YEGoodsReceipt",
				"YEInTransit",
				"YEEffects",
				"YECycle"
			]}
			defs={[
				{
					id: "YESafety",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#3D3C3A" },
						{ offset: 100, color: "#3D3C3A" },
					],
				},
				{
					id: "YEGoodsIssued",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#555555" },
						{ offset: 100, color: "#555555" },
					],
				},
				{
					id: "YEGoodsReceipt",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#504A4B" },
						{ offset: 100, color: "#504A4B" },
					],
				},

				{
					id: "YEInTransit",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#666362" },
						{ offset: 100, color: "#666362" },
					],
				},
				{
					id: "YECycle",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#A5A5A5" },
						{ offset: 100, color: "#A5A5A5" },
					],
				},

				{
					id: "YEEffects",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#808080" },
						{ offset: 100, color: "#808080" },
					],
				},
				{
					id: "Safety",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#015A79" },
						{ offset: 100, color: "#015A79" },
					],
				},
				{
					id: "GoodsIssued",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#3A6979" },
						{ offset: 100, color: "#3A6979" },
					],
				},
				{
					id: "GoodsReceipt",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#4B8499" },
						{ offset: 100, color: "#4B8499" },
					],
				},

				{
					id: "InTransit",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#60A6BF" },
						{ offset: 100, color: "#60A6BF" },
					],
				},
				{
					id: "Cycle",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#66B7D4" },
						{ offset: 100, color: "#66B7D4" },
					],
				},

				{
					id: "Effects",
					type: "linearGradient",
					colors: [
						{ offset: 0, color: "#91CDE3" },
						{ offset: 100, color: "#91CDE3" },
					],
				},

				
				linearGradientDef(
					"CURRENT",
					[
						{ offset: 0, color: "#97EE27" },
						{ offset: 100, color: "#89D329" },
					],

					{
						gradientTransform: "rotate(180 0.5 0.5)",
					}
				),

				linearGradientDef(
					"MA12Proj",
					[
						{ offset: 0, color: "#79CD0C" },
						{ offset: 100, color: "#5B9E05" },
					],

					{
						gradientTransform: "rotate(180 0.5 0.5)",
					}
				),

				linearGradientDef(
					"YETarget",
					[
						{ offset: 0, color: "#DEF4C0" },
						{ offset: 100, color: "#BFD6A1" },
					],

					{
						gradientTransform: "rotate(180 0.5 0.5)",
					}
				),
				linearGradientDef(
					"YE_Target",
					[
						{ offset: 0, color: "#40E0D0" },
						{ offset: 100, color: "#60E0D0" },
					],

					{
						gradientTransform: "rotate(180 0.5 0.5)",
					}
				),

				
			]}
			indexBy="Id"
			margin={{ top: 20, right: 20, bottom: 50, left: 70 }}
			padding={0.6}
			valueScale={{ type: "linear" }}
			groupMode="stacked"
			indexScale={{type:"band"}}
			

			//minValue="0"
			//minValue={minYvalue ? parseInt(minYvalue) : 0}
			 //maxValue={maxYvalue ? parseInt(maxYvalue) * 1.2 : 100}
			fill={[
				// match using object query
				{ match: { id: "CURRENT" }, id: "CURRENT" },
				{ match: { id: "MA12Proj" }, id: "MA12Proj" },
				{ match: { id: "YeTarget" }, id: "YETarget" },
				{ match: { id: "YE_Target" }, id: "YE_Target" },
				{ match: { id: "Safety" }, id: "Safety" },
				{ match: { id: "GoodsIssued" }, id: "GoodsIssued" },
				{ match: { id: "GoodsReceipt" }, id: "GoodsReceipt" },
				{ match: { id: "InTransit" }, id: "InTransit" },
				{ match: { id: "Cycle" }, id: "Cycle" },
				{ match: { id: "Effects" }, id: "Effects" },
				{ match: { id: "YESafety" }, id: "YESafety" },
				{ match: { id: "YEGoodsIssued" }, id: "YEGoodsIssued" },
				{ match: { id: "YEGoodsReceipt" }, id: "YEGoodsReceipt" },
				{ match: { id: "YEInTransit" }, id: "YEInTransit" },
				{ match: { id: "YECycle" }, id: "YECycle" },
				{ match: { id: "YEEffects" }, id: "YEEffects" },
				
				
			]}
			borderColor={{
				from: "color",
				modifiers: [["darker", 1.6]],
			}}
			// markers={[
			// 	{
			// 		axis: "y",
			// 		value: `${mk}`,
			// 		lineStyle: { stroke: "#9747FF", strokeWidth: 1 },
			// 	},
			// ]}
			axisTop={{
				tickSize: 0,
				tickPadding: 5,
				tickRotation: 0,
				legendPosition: "start",
				legendOffset: 32,
				format: (d) =>(data!=null)?(data.map((item)=>{if((item.Id!=="CURRENT" && item.Id!=="MA12Proj" 
					&& item.Id!=="Ye Target" && item.Id!=="YE_Target" && item.Id!=="YE_Forecast" 
					&& item.Id.toLowerCase())===d.toLowerCase()) 
					return (item.total.toLocaleString("en-US"))})):""


			}}
			axisRight={null}
			axisBottom={{
				tickSize: 2,
				tickPadding: 10,
				tickRotation:0,
				legendPosition: "start",
				legendOffset: 32,
				renderTick: ({
					opacity,
					textAnchor,
					textBaseline,
					textX,
					textY,
					theme,
					value,
					x,
					y
				  }) => {
					return (
					  <g
						transform={`translate(${x},${y})`}
						style={{ opacity }}
					  >
						<text
						  alignmentBaseline={textBaseline}
						  textAnchor={textAnchor}
						  transform={`translate(${textX},${textY})`} style={{fontSize:"1.1rem"}}
						>	
						{value=(value == "CURRENT"
															? getTspanGroups("Total Inventory",15)
															: value == "MA12Proj"
															? getTspanGroups("BIIR 12MA Projected Inventory(owned)",15)
															: value =="Ye Target"? getTspanGroups("YE BIIR Projected Inventory(owned)",15)
															: value =="YE_Target"? getTspanGroups("YE Inventory Target",15)
															: value =="YE_Forecast"? getTspanGroups("YE Inventory FC(Norm@as-is)",12)
															: value == "Historized" || value == "AS-IS" || value == "Customized" || value == "Improved" || value == "YE_Forecast"
															? getTspanGroups(`Norm+ @${value.toLowerCase()} (smoothened)`,15):"")
									}
						  
						</text>
					  </g>
					)
				  }
				}}
				
			animate={true}
			motionStiffness={90}
			motionDamping={15}
			barComponent={BarComponent}
			axisLeft={{
				tickSize: 5,
				tickValues:12,
				tickPadding: 5,
				tickRotation: 0,
				
				format: function(value) {
					if(value!=null && value!=undefined)
					  {
						if(value>=1000000000)
						{
							return (value/1000000000) +"Bln"
						}	

					  else if(value>=1000000)
					   {
						   return (value/1000000) +"Mln"
					   }
					   else if(value>=1000)
					   {
						return (value/1000) + 'K'
					   }
					   else if (value < 1000) {
						return value
					    }
						else 
						{
							return value
						}
					}
					}
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{
				from: "color",
				modifiers: [["darker", 1.6]],
			}}
		/>
	);
};

const NormChart = (props) => {
	const data =getMaxValue(props.chartData);

//console.log("data",data);
	if (data != null && data != undefined && data.length > 0) 
	return( <>
	<MyResponsiveBar data={data} />
	</>);
	else
		return (
			<Nodata message="No Data"/>
		);
};

function getInsetValue(bar) {
	if (
		bar.data.data.Id.toLowerCase() == "as-is" ||
		bar.data.data.Id.toLowerCase() == "historized" ||
		bar.data.data.Id.toLowerCase() == "improved" ||
		bar.data.data.Id.toLowerCase() == "customized"
		
	) {
		if (bar.data.data.Effects != undefined && bar.data.id == "Effects") 
		{
			return "inset(1% -1% -5% -1% round 5px)";
		} 
		else if (
			(bar.data.data.Effects == undefined || bar.data.data.Effects == null || bar.data.data.Effects == 0) &&
			bar.data.data.Cycle != undefined && bar.data.data.InTransit == undefined &&
			bar.data.data.GoodsReceipt == undefined && bar.data.data.GoodsIssued == undefined && bar.data.data.GoodsIssued=== 0 &&
			bar.data.data.Safety == undefined && bar.data.data.Safety=== 0 &&
			bar.data.id == "Cycle"
		) {
			return "inset(1% -1% -5% -1% round 5px)";
		} else if (
			(bar.data.data.Effects == undefined || bar.data.data.Effects == null || bar.data.data.Effects == 0) &&
			(bar.data.data.Cycle == undefined || bar.data.data.Cycle == null || bar.data.data.Cycle == 0) &&
			bar.data.data.InTransit != undefined && bar.data.data.GoodsReceipt == undefined &&
			bar.data.data.GoodsIssued == undefined && bar.data.data.GoodsIssued=== 0 &&
			bar.data.data.Safety == undefined && bar.data.data.Safety=== 0 &&
			bar.data.id == "InTransit"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else if (
			(bar.data.data.Effects == undefined || bar.data.data.Effects == null || bar.data.data.Effects == 0) &&
			(bar.data.data.Cycle == undefined || bar.data.data.Cycle == null || bar.data.data.Cycle == 0) &&
			bar.data.data.InTransit == undefined &&
			bar.data.data.GoodsReceipt != undefined &&
			bar.data.id == "GoodsReceipt"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else if (
			(bar.data.data.Effects == undefined || bar.data.data.Effects == null || bar.data.data.Effects == 0) &&
			(bar.data.data.Cycle == undefined || bar.data.data.Cycle == null || bar.data.data.Cycle == 0) &&
			bar.data.data.InTransit == undefined &&
			bar.data.data.GoodsReceipt == undefined &&
			bar.data.data.GoodsIssued != undefined &&
			bar.data.id == "GoodsIssued"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else if (
			(bar.data.data.Effects == undefined || bar.data.data.Effects == null || bar.data.data.Effects == 0) &&
			(bar.data.data.Cycle == undefined || bar.data.data.Cycle == null || bar.data.data.Cycle == 0) &&
			bar.data.data.InTransit == undefined &&
			bar.data.data.GoodsReceipt == undefined &&
			bar.data.data.GoodsIssued == undefined &&
			bar.data.data.Safety != undefined &&
			bar.data.id == "Safety"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else return "";
	}else if(bar.data.data.Id.toLowerCase() == "ye_forecast")
	{

		if (bar.data.data.YEEffects != undefined && bar.data.id == "YEEffects") {
			return "inset(1% -1% -5% -1% round 5px)";
		} else if (
			(bar.data.data.YEEffects == undefined || bar.data.data.YEEffects == null || bar.data.data.YEEffects == 0) &&
			bar.data.data.YECycle != undefined && 	bar.data.data.YEInTransit == undefined &&
			bar.data.data.YEGoodsReceipt == undefined &&
			bar.data.data.YEGoodsIssued == undefined && bar.data.data.YEGoodsIssued=== 0 &&
			bar.data.data.YESafety == undefined && bar.data.data.YESafety=== 0 &&
			bar.data.id == "YECycle"
		) {
			return "inset(1% -1% -5% -1% round 5px)";
		} else if (
			(bar.data.data.YEEffects == undefined || bar.data.data.YEEffects == null || bar.data.data.YEEffects == 0) &&
			(bar.data.data.YECycle == undefined || bar.data.data.YECycle == null || bar.data.data.YECycle == 0) &&
			bar.data.data.YEInTransit != undefined && bar.data.data.YEGoodsReceipt == undefined &&
			bar.data.data.YEGoodsIssued == undefined && bar.data.data.YEGoodsIssued=== 0 &&
			bar.data.data.YESafety == undefined && bar.data.data.YESafety=== 0 &&
			bar.data.id == "YEInTransit"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else if (
			(bar.data.data.YEEffects == undefined || bar.data.data.YEEffects == null || bar.data.data.YEEffects == 0) &&
			(bar.data.data.YECycle == undefined || bar.data.data.YECycle == null || bar.data.data.YECycle == 0) &&
			bar.data.data.YEInTransit == undefined &&
			bar.data.data.YEGoodsReceipt != undefined &&
			bar.data.id == "YEGoodsReceipt"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else if (
			(bar.data.data.YEEffects == undefined || bar.data.data.YEEffects == null || bar.data.data.YEEffects == 0) &&
			(bar.data.data.YECycle == undefined || bar.data.data.YECycle == null || bar.data.data.YECycle == 0) &&
			bar.data.data.YEInTransit == undefined &&
			bar.data.data.YEGoodsReceipt == undefined &&
			bar.data.data.YEGoodsIssued != undefined &&
			bar.data.id == "YEGoodsIssued"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else if (
			(bar.data.data.YEEffects == undefined || bar.data.data.YEEffects == null || bar.data.data.YEEffects == 0) &&
			(bar.data.data.YECycle == undefined || bar.data.data.YECycle == null || bar.data.data.YECycle == 0) &&
			bar.data.data.YEInTransit == undefined &&
			bar.data.data.YEGoodsReceipt == undefined &&
			bar.data.data.YEGoodsIssued == undefined &&
			bar.data.data.YESafety != undefined &&
			bar.data.id == "YESafety"
		)
			return "inset(1% -1% -5% -1% round 5px)";
		else return "";
	}
	
	
	else if (
		bar.data.fill === "url(#CURRENT)" ||
		bar.data.fill === "url(#MA12Proj)" ||
		bar.data.fill === "url(#YETarget)" ||
		bar.data.fill === "url(#YE_Target)"
	)
		return "inset(1% -1% -5% -1% round 5px)";
}


function getCustomHeight(stack)
{

	if((stack.data.id==="InTransit" || stack.data.id==="YEInTransit")  && stack.data.value>0 && stack.height===0)
	{
			
		if (stack.y >= 100) {
			return (stack.y/100)+10;
			}
			else if(stack.y>10)
			{
				return (stack.y/10)+10;
			}
		
		
	}
	else if((stack.data.id==="GoodsReceipt" || stack.data.id==="YEGoodsReceipt")   && stack.data.value>0 && stack.height===0)

	{
		if (stack.y >= 100) {
			return (stack.y/100)+10;
			}
			else if(stack.y>10)
			{
				return (stack.y/10)+10;
			}
		

	}
	else if((stack.data.id==="GoodsIssued" || stack.data.id==="YEGoodsIssued")  && stack.data.value>0 && stack.height===0)
	{

		if (stack.y >= 100) {
			return (stack.y/100)+10;
			}
			else if(stack.y>10)
			{
				return (stack.y/10)+10;
			}
	}
	else if((stack.data.id==="Safety" || stack.data.id==="YESafety")  && stack.data.value>0 && stack.height===0)
	{
		if (stack.y >= 100) {
			return (stack.y/100)+10;
			}
			else if(stack.y>10)
			{
				return (stack.y/10)+10;
			}
	}
	else if((stack.data.id==="Effects" || stack.data.id==="YEEffects")  && stack.data.value>0 && stack.height===0)
	{
		if (stack.y >= 100) {
			return (stack.y/100)+10;
			}
			else if(stack.y>10)
			{
				return (stack.y/10)+10;
			}
		
	}
	else if((stack.data.id==="Cycle" || stack.data.id==="YECycle")  && stack.data.value>0 && stack.height===0)
	{
		if (stack.y >= 100) {
			return (stack.y/100) +10;
			}
			else if(stack.y>10)
			{
				return (stack.y/10)+10;
			}
		
	}
	else
	return stack.height;



}

const getTspanGroups = (value, maxLineLength, maxLines= 3) => {


	const words = value.split(' ')
	
	let linesAcc = {
		lines:[],
		currLine:""
	}

	//reduces the words into lines of maxLineLength
	const assembleLines= words.reduce( (acc, word) => {
		//if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
		if ( (word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
			return {
				lines: acc.lines.concat([acc.currLine]),
				currLine: word
			}
		}
		//otherwise add the word to the current line
		return {
			...acc,
			currLine: acc.currLine + ' ' + word 
		} 
		
	}, {lines: [], currLine: ''})

	//add the ending state of current line (the last line) to lines
	const allLines = assembleLines.lines.concat([assembleLines.currLine])

	//for now, only take first 2 lines due to tick spacing and possible overflow
	const lines = allLines.slice(0, maxLines)
	let children = []
	let dy = 0

	lines.forEach( (lineText, i) => {
		children.push(
			<tspan x={0} dy={dy} key={i} style={{fontSize:"1.1rem"}}>
				{
					// if on the second line, and that line's length is within 3 of the max length, add ellipsis
					lineText	//(1 === i && allLines.length > 2) ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
				}
			</tspan> 
		)
		//increment dy to render next line text below
		dy = 12
	});

	return children
}



export default NormChart;
