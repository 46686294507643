
import React,{useState, useContext} from 'react';
import pfContext from '../../context/PfContext';
import { FilterDropDown1 } from "./filterDropDown1";
import { Box, Stack } from '@mui/material';
import { FilterDropDownSingle } from './filterDropDownSingle';
import { useEffect } from 'react';


export default function PfFilter({filterType,tableName,setIsGsmChangedAfterPreview,isCleared,setIsCleared,isPrevSceLoad,isSaveDone,isInitLoad}) {
    const context=useContext(pfContext);
    const [steps,setSteps]=useState([]);
    const [gsmKey,setGsmKey]=useState("GSM");
    const [brandKey,setBrandKey]=useState("Brand");
    const [locKey,setLocKey]=useState("Location");
    const [comKey,setCoMKey]=useState("CoM");
    const [proKey,setProKey]=useState("Product");
    const[selectedGSM,setSelectedGSM]=useState([]);
    const[selectedBrand,setSelectedBrand]=useState([]);
    const[selectedCoM,setSelectedCoM]=useState([]);
    const[selectedProduct,setSelectedProduct]=useState([]);
    const [selectedLocation,setSelectedLocation]=useState([]);
    const [gsmDefaultValue,setGsmDefaultValue]=useState(null);
    const [brandDefaultValue,setBrandDefaultValue]=useState(null);
    const [locDefaultValue,setLocDefaultValue]=useState(null);
    const [comDefaultValue,setComDefaultValue]=useState(null);
    const [proDefaultValue,setProDefaultValue]=useState(null);
    const [isScenarioLoaded,setIsScenarioLoaded]=useState(isPrevSceLoad);
    
    useEffect(()=>{
      if(isCleared){
        resetOtherDrop("GSM","clear");
        resetOtherDrop("Brand","clear");
        resetOtherDrop("CoM","clear");
        resetOtherDrop("Location","clear");
        resetOtherDrop("Product","clear");
        setIsCleared(false);
      }
    },[isCleared])

    //console.log('steps',context);
    const updateSteps = (type, action) => {
      // Use functional update to ensure the latest state.
      // This prevents issues where the state might be stale due to React's asynchronous updates.
      setSteps((prevSteps) => {
    
        // Add 'type' if it's not in 'steps'.
        if (!prevSteps.includes(type)) {
          return [...prevSteps, type];
        } else {
          resetOtherDrop(type, action);
    
          // Return unchanged steps.
          return prevSteps;
        }
      });
    };

    const generateRandomKey=()=>{
      return Math.floor(Date.now() * Math.random());
    }
    

    useEffect(()=>{
      console.log('steps',steps);
    },[steps])
    const resetOtherDrop=(type,actionType)=>
    {
        let indexOfValue=steps?.indexOf(type)+1;
        let newSteps = [...steps];
      //console.log("indexOfValue",indexOfValue);
      //console.log("indexOfValue",steps.length);
      if (indexOfValue <= steps.length) {
      
        for (let i = steps.length; i >= (indexOfValue); i--) {

          if (steps[i] == "Brand") {
         
            newSteps.splice(i, 1);
            setSelectedBrand([]);
            setBrandKey(generateRandomKey)
            setBrandDefaultValue(null);
            context.setPipeline([]);
            //setBrandDefaultValue("");
          }
          else if (steps[i] == "GSM") {
            setSelectedGSM([]);
            newSteps.splice(i, 1);
            setGsmKey(generateRandomKey)
            //setGsmDefaultValue("");
            setGsmDefaultValue(null);
            context.setGsmNetwork([]);
          }

          else if (steps[i] == "Location") {
            setSelectedLocation([]);
            newSteps.splice(i, 1);
            
             setLocKey(generateRandomKey);
             setLocDefaultValue(null)
             context.setLocation([]);
            // setLocDefaultValue("");
          
          }
          else if (steps[i] == "CoM") {
            setSelectedCoM([]);
            newSteps.splice(i, 1);
            setCoMKey(generateRandomKey)
            setComDefaultValue(null)
            context.setCom([]);
            //setComDefaultValue("");
          }
          else if (steps[i] == "Product") {
            setSelectedProduct([]);
            newSteps.splice(i, 1);
             setProKey(generateRandomKey)
             setProDefaultValue(null);
             context.setProduct([]);
            // setProDefaultValue("")
          }
        }
        if(actionType==="clear")
          { newSteps.pop();

            if (type === "Brand") {
         
            
              setSelectedBrand([]);
              setBrandKey(generateRandomKey);
              setBrandDefaultValue(null);
              context.setPipeline([]);
              
              //setBrandDefaultValue("");
            }
            else if (type === "GSM") {
              setSelectedGSM([]);
              setGsmKey(generateRandomKey)
              setGsmDefaultValue(null);
              context.setGsmNetwork([]);
              //setGsmDefaultValue("");
            }
  
            else if (type === "Location") {
              setSelectedLocation([]);
               setLocKey(generateRandomKey);
               setLocDefaultValue(null);
               context.setLocation([]);
               //setLocDefaultValue("");
            
            }
            else if (type === "CoM") {
              setSelectedCoM([]);
              setCoMKey(generateRandomKey);
              setComDefaultValue(null);
              context.setCom([]);
              //setComDefaultValue("");
            
            }
            else if (type === "Product") {
              setSelectedProduct([]);
             setProKey(generateRandomKey)
             setProDefaultValue(null);
             context.setProduct([]);
             //setProDefaultValue("")
            }
          
          }
       setSteps([...newSteps]);

      }
        //if(indexOfValue!)
    }
  //  useEffect(()=>{
  //   //console.log("context.gsmNetwork.length",context.gsmNetwork.length);
  //  // console.log("context.gsmNetwork.length",context.gsmNetwork);
  //   if(context.gsmNetwork.length>0)
  //   {
  //     //setGsmKey(generateRandomKey)
  //    // console.log("context.gsmNetwork.length",context.gsmNetwork.length);
  //     setSelectedGSM(context.gsmNetwork);
      
  //     //setGsmDefaultValue("");
  //   }

  //  },[context.gsmNetwork])
  useEffect(() => {
    if (isPrevSceLoad || isInitLoad) {
      setIsScenarioLoaded(isPrevSceLoad);
      if (context.gsmNetwork.length > 0) {
        setSelectedGSM(context.gsmNetwork);
        if (isInitLoad) {
          updateSteps('GSM', "select-option");
        }
      }
  
      if (context.pipeline.length > 0) {
        setSelectedBrand(context.pipeline);
        if (isInitLoad) {
          updateSteps('Brand', "select-option");
        }
      }
  
      if (context.com.length > 0) {
        setSelectedCoM(context.com);
        if (isInitLoad) {
          updateSteps('CoM', "select-option");
        }
      }
  
      if (context.product.length > 0) {
        setSelectedProduct(context.product);
        if (isInitLoad) {
          updateSteps('Product', "select-option");
        }
      }
      if (context.location.length > 0) {
        setSelectedLocation(context.location);
        if (isInitLoad) {
          updateSteps('Location', "select-option");
        }
      }
    }
  }, [
    isPrevSceLoad,
    isInitLoad,
    context.pipeline,
    context.gsmNetwork,
    context.com,
    context.product,
    context.location
  ]);
  
  //  useEffect(()=>{
  //   if(context.com.length>0)
  //   {
  //     //setCoMKey(generateRandomKey)
  //     setSelectedCoM(context.com);
  //     //setGsmDefaultValue("");
  //   }
  //  },[context.com])

  //  useEffect(()=>{
  //   if(context.product.length>0)
  //   {
  //     //setProKey(generateRandomKey)
  //     setSelectedProduct(context.product);
      
  //     //setGsmDefaultValue("");
  //   }
  //  },[context.product])

  //  useEffect(()=>{
  //   if(context.location.length>0)
  //   {
  //     //setLocKey(generateRandomKey)
  //     setSelectedLocation(context.location);
  //     //setGsmDefaultValue("");
  //   }
  //  },[context.location])

  // useEffect(()=>{
  //   setIsGsmSelectAll(context.isGsmSelectAll)

  // },[context.isGsmSelectAll])

   

  return (
    <div style={{zIndex:11}}>
      <Stack direction="row" spacing={2} sx={{ flexGrow: 1, justifyContent: "center" }}>
        <Box style={{width:"18rem"}}>
          {(filterType==="PF")?<FilterDropDownSingle type="GSM" title="GSM" value={context.gsmNetwork} onChange={context.setGsmNetwork}
           updateSteps={updateSteps} tableName={tableName} setIsGsmChangedAfterPreview={setIsGsmChangedAfterPreview} isSaveDone={isSaveDone}
           dependency={gsmKey} selectedOptions={selectedGSM} setSelectedOptions={setSelectedGSM} 
            isAllSelected={context.isGsmSelectAll}/>
              :
              <FilterDropDown1 type="GSM"  title="Gsm" value={context.gsmNetwork} isPrevSceLoad={isScenarioLoaded} onChange={context.setGsmNetwork} defaultOptionValue={"All"} 
              updateSteps={updateSteps} dependency={gsmKey} selectedOptions={selectedGSM} 
              setSelectedOptions={setSelectedGSM} defaultValue={gsmDefaultValue} setDefaultValue={setGsmDefaultValue} 
              isAllSelected={context.isGsmSelectAll} setIsAllSelected={context.setIsGsmSelectAll}
              />
          }
     </Box>
       <Box style={{width:"18rem"}}><FilterDropDown1 type="Brand"  title="Pipeline" isPrevSceLoad={isScenarioLoaded} value={context.pipeline} onChange={context.setPipeline} defaultOptionValue={"All"} 
       updateSteps={updateSteps} dependency={brandKey} selectedOptions={selectedBrand} setSelectedOptions={setSelectedBrand}
       defaultValue={brandDefaultValue} setDefaultValue={setBrandDefaultValue} isAllSelected={context.isBrandSelectAll} 
       setIsAllSelected={context.setIsBrandSelectAll}
       /></Box>
       <Box style={{width:"18rem"}}> <FilterDropDown1 type="Location"  title="Location" isPrevSceLoad={isScenarioLoaded} value={context.location}  onChange={context.setLocation}  defaultOptionValue={"All"} 
       updateSteps={updateSteps} dependency={locKey} selectedOptions={selectedLocation} 
       defaultValue={locDefaultValue} setDefaultValue={setLocDefaultValue} isAllSelected={context.isLocationSelectAll} setIsAllSelected={context.setIsLocationSelectAll}
       setSelectedOptions={setSelectedLocation} 
       /></Box>
        <Box style={{width:"18rem"}}> <FilterDropDown1 type="CoM"  title="CoM" isPrevSceLoad={isScenarioLoaded} value={context.com} onChange={context.setCom}  defaultOptionValue={"All"} 
        updateSteps={updateSteps} dependency={comKey} selectedOptions={selectedCoM} defaultValue={comDefaultValue} 
        isAllSelected={context.isCoMSelectAll} setIsAllSelected={context.setIsCoMSelectAll}
        setDefaultValue={setComDefaultValue}
        setSelectedOptions={setSelectedCoM}
       /></Box>
       <Box style={{width:"18rem"}}> <FilterDropDown1 type="Product" title="Material" isPrevSceLoad={isScenarioLoaded} value={context.product} onChange={context.setProduct} defaultOptionValue={"All"}
       updateSteps={updateSteps} dependency={proKey}  selectedOptions={selectedProduct} 
       isAllSelected={context.isProductSelectAll} setIsAllSelected={context.setIsProductSelectAll}
       defaultValue={proDefaultValue} setDefaultValue={setProDefaultValue}
       setSelectedOptions={setSelectedProduct} 
       /></Box>
      
       </Stack>
    </div>
  )
}
